#fade-out {

   animation-duration: 0.8s;
    animation-name: fadeOut;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    85% {
        opacity: 0.8
    }

    100% {
        opacity: 0;
        transform: translateX(-100%)
    }
}

#fade-outimg {

   animation-duration: 0.8s;
    animation-name: fadeOutImg;
}

@keyframes fadeOutImg {
    0% {
        opacity: 1;
    }

    85% {
        opacity: 0
    }

    100% {
        opacity: 0;
        transform: translateX(-100%)
    }
}

#fade-outpage {

   animation-duration: 0.8s;
    animation-name: fadeOutPage;
}

@keyframes fadeOutPage {
    0% {
        opacity: 1;
        transform: translateY(0%)
    }

    50% {
        opacity: 0;
        transform: translateY(80%)
    }

    100% {
        opacity: 0;
        transform: translateY(100%)
    }
}

#fade-outbg {

   animation-duration: 0.8s;
    animation-name: fadeOutBg;
}

@keyframes fadeOutBg {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0;
        transform: translateX(-35%)
    }
}

#fade-outlogo {

   animation-duration: 0.8s;
    animation-name: fadeOutLogo;
}

@keyframes fadeOutLogo {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0;
        transform: translateX(-35%)
    }
}

#fade-inpred {
   animation-duration: 0.8s;
    animation-name: fadeInPred;
}

@keyframes fadeInPred {
    0% {
        opacity: 0.5;
        transform: translateX(0%);
    }

    50% {
        opacity: 0.8;

    }

    100% {
        opacity: 1;

    }
}

#fade-intext {
   animation-duration: 0.8s;
    animation-name: fadeInText;
}

@keyframes fadeInText {
    0% {
        opacity: 0;
        transform: translateX(5%)
    }

    50% {
        opacity: 0.5;

    }

    100% {
        opacity: 1;

    }
}

#fade-inpage {
   animation-duration: 0.8s;
    animation-name: fadeInPage;
}

@keyframes fadeInPage {
    0% {
        opacity: 0;
        transform: translateY(-100%)
    }

    100% {
        opacity: 1;
        transform: translateY(0%)
    }
}

#fade-inlogo {
   animation-duration: 0.8s;
    animation-name: fadeInLogo;
}

@keyframes fadeInLogo {
    0% {
        opacity: 0;
        transform: translateX(35%)
    }

    100% {
        opacity: 1;

    }
}

#fade-inpred1 {
   animation-duration: 0.8s;
    animation-name: fadeInPred1;
}

@keyframes fadeInPred1 {
    0% {
        opacity: 0;
        transform: translateY(-50%)
    }

    50% {
        opacity: 0.5;

    }

    100% {
        opacity: 1;

    }
}

#fade-inpredopen {
   animation-duration: 0.8s;
    animation-name: fadeInPredOpen;
}

@keyframes fadeInPredOpen {
    0% {
        opacity: 0;
        transform: translate(-70%, 0) scale(0.5)
    }

    50% {
        opacity: 0.5;

    }

    100% {
        opacity: 1;

    }
}

#fade-inpredValue {
   animation-duration: 0.8s;
    animation-name: fadeInPredValue;

}

@keyframes fadeInPredValue {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;

    }

    100% {
        opacity: 1;

    }
}

#fade-outpredpage {
   animation-duration: 0.8s;
    animation-name: fadeOutPredPage;
    animation-fill-mode: both;

}

@keyframes fadeOutPredPage {
    0% {
        opacity: 0.5;
        transform: translateX(-100%);
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 1;
    }
}

#fade-outpredpagenum {
   animation-duration: 0.8s;
    animation-name: fadeOutPredPageNum;
    animation-fill-mode: both;

}

@keyframes fadeOutPredPageNum {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(-100%);

    }
}

#fade-inpredlogo {
   animation-duration: 0.8s;
    animation-name: fadeInPredLogo;
}

@keyframes fadeInPredLogo {
    0% {
        opacity: 0;
        transform: translateX(35%)
    }

    100% {
        opacity: 1;

    }
}

#fade-inpreddesc {
   animation-duration: 0.8s;
    animation-name: fadeInPredDesc;
}

@keyframes fadeInPredDesc {
    0% {
        opacity: 0;
        transform: translateX(15%)
    }

    100% {
        opacity: 1;

    }
}

#fade-insolopen {
   animation-duration: 0.8s;
    animation-name: fadeInSolOpen;
}

@keyframes fadeInSolOpen {
    0% {
        opacity: 0;
        transform: translateX(-25%) scale(0.5)
    }

    50% {
        opacity: 0.5;

    }

    100% {
        opacity: 1;

    }
}

#fade-inpredpage {
   animation-duration: 0.8s;
    animation-name: fadeInPredPage;
    animation-fill-mode: both;
}

@keyframes fadeInPredPage {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

#fade-outpred {
   animation-duration: 0.8s;
    animation-name: fadeOutPred;
    animation-fill-mode: both;
    transition: transform 0.8s linear;
    transform-origin: left;
    transform-style: preserve-3D;
    transform: scale(0);
}

@keyframes fadeOutPred {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;

    }

    100% {
        opacity: 0;
    }
}

#fade-inpageabout {
   animation-duration: 0.8s;
    animation-name: fadeInPageAbout;
}

@keyframes fadeInPageAbout {
    0% {
        opacity: 0;
        transform: translateY(50vh) translateX(-100%)
    }

    100% {
        opacity: 1;
    }
}

#fade-inaboutdesc {
   animation-duration: 0.8s;
    animation-name: fadeInAboutDesc;
    animation-fill-mode: both;
}

@keyframes fadeInAboutDesc {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
    }
}


.team_info:hover {
    #fade-inteamdesc {
        display: flex !important;
       animation-duration: 0.8s;
        animation-name: fadeInTeamDesc;
        animation-fill-mode: both;

        @keyframes fadeInTeamDesc {
            0% {
                opacity: 0.5;
                transform: translateY(25%) translateX(-30%) scaleY(0.5) scaleX(0.5);
            }

            100% {
                opacity: 1;

            }
        }
       
    }
    #fade-inteamdescleft {
        display: flex !important;
       animation-duration: 0.8s;
        animation-name: fadeInTeamDescLeft;
        animation-fill-mode: both;

        @keyframes fadeInTeamDescLeft {
            0% {
                opacity: 0.5;
                transform: translateY(25%) translateX(30%) scaleY(0.5) scaleX(0.5);
            }

            100% {
                opacity: 1;

            }
        }
    }
}

#fade-inteam {
   animation-duration: 0.8s;
    animation-name: fadeInTeam;
}

@keyframes fadeInTeam {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;

    }
    100% {
        opacity: 1;

    }
}