.header_mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  min-height: 60px;

  .grid_1 {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
  }

  .grid_2 {
    grid-area: 1 / 2 / 2 / 3;
    position: relative;
  }

  .grid_3 {
    grid-area: 1 / 3 / 2 / 4;
    position: relative;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
  }

  .border {
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
  }

  .burger {
    position: absolute;
    left: 26.5px;
    top: 26.5px;
  }

  .logo_wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    justify-content: space-between;
    width: 100%;

    .logo {
      width: 40px;
      height: 40px;
    }

    h2 {
      font-size: 16px;
      line-height: 19px;
      color: #283835;
    }
  }

  .line {
    width: 68px;
    height: 2px;
    background: #283835;
    position: absolute;
    top: 36.5px;
    left: 10px;
  }
}

.grid_mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: calc(100vh - 10vh);

  .main_1 {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
    min-height: 125px;
  }

  .main_2 {
    grid-area: 1 / 2 / 2 / 3;
    position: relative;
    min-height: 125px;
  }

  .main_3 {
    grid-area: 1 / 3 / 2 / 4;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
    min-height: 125px;
  }

  .main_4 {
    grid-area: 2 / 1 / 3 / 2;
    position: relative;
    min-height: 125px;
  }

  .main_5 {
    grid-area: 2 / 2 / 3 / 3;
    position: relative;
    min-height: 125px;
  }

  .main_6 {
    grid-area: 2 / 3 / 3 / 4;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
    position: relative;
  }

  .main_7 {
    grid-area: 3 / 1 / 4 / 2;
    position: relative;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .main_8 {
    grid-area: 3 / 2 / 4 / 3;
    position: relative;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .main_9 {
    grid-area: 3 / 3 / 4 / 4;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
    min-height: 125px;
  }

  .main_10 {
    grid-area: 4 / 1 / 5 / 2;
    position: relative;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .main_11 {
    grid-area: 4 / 2 / 5 / 3;
    position: relative;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .main_12 {
    grid-area: 4 / 3 / 5 / 4;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
    min-height: 125px;
  }

  .main_13 {
    grid-area: 5 / 1 / 6 / 2;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
    min-height: 125px;
  }

  .main_14 {
    grid-area: 5 / 2 / 6 / 3;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
    min-height: 125px;
  }

  .main_15 {
    grid-area: 5 / 3 / 6 / 4;
    position: relative;
    min-height: 125px;
  }

  .border {
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
  }
}

.grid_mob_homepage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: calc(100vh - 60px);
  display: grid;

  .grid_1 {
    grid-area: 1 / 1 / 2 / 6;
    position: relative;
    min-height: 314px;
  }

  .grid_2 {
    grid-area: 2 / 1 / 3 / 2;
    position: relative;
    min-height: 125px;
  }

  .grid_3 {
    grid-area: 2 / 2 / 3 / 3;
    position: relative;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .grid_4 {
    grid-area: 2 / 3 / 3 / 4;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: none;
    position: relative;
    min-height: 125px;
  }

  .grid_5 {
    grid-area: 3 / 1 / 4 / 2;
    position: relative;
    min-height: 125px;
  }

  .grid_6 {
    grid-area: 3 / 2 / 4 / 3;
    position: relative;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .grid_7 {
    grid-area: 3 / 3 / 4 / 4;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
    min-height: 125px;
  }

  .grid_8 {
    grid-area: 4 / 1 / 5 / 2;
    position: relative;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .grid_9 {
    grid-area: 4 / 2 / 5 / 3;
    position: relative;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    min-height: 125px;
  }

  .grid_10 {
    grid-area: 4 / 3 / 5 / 4;
    position: relative;
    min-height: 125px;
  }

  .border {
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
  }
}

.grid_prediction {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .pred_1 {
    grid-area: 1 / 1 / 2 / 4;
    position: relative;
  }

  .pred_2 {
    grid-area: 2 / 1 / 3 / 4;
    position: relative;
  }

  .pred_3 {
    grid-area: 3 / 1 / 4 / 2;
    position: relative;
  }

  .pred_4 {
    grid-area: 3 / 2 / 4 / 3;
    position: relative;
  }

  .pred_5 {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
  }

  .pred_6 {
    grid-area: 4 / 1 / 5 / 2;
    position: relative;
  }

  .pred_7 {
    grid-area: 4 / 2 / 5 / 3;
    position: relative;
  }

  .pred_8 {
    grid-area: 4 / 3 / 5 / 4;
    position: relative;
  }

  .pred_9 {
    grid-area: 5 / 1 / 6 / 2;
    position: relative;
  }

  .pred_10 {
    grid-area: 5 / 2 / 6 / 3;
    position: relative;
  }

  .pred_11 {
    grid-area: 5 / 3 / 6 / 4;
    position: relative;
  }
}

.img_homepage_mob {
  width: 100%;
  position: absolute;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.title {
  font-weight: 400;
  font-size: 24px;
  color: #283835;
  position: absolute;
  top: 8vh;
  width: 100%;
}

.top {
  width: 50vw;
  top: 12vh;
  padding-left: 12px;
}

.protonation_title {
  width: 65vw;
  padding-top: 12px;
  position: absolute;
  bottom: 0;

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #283835;
    margin-bottom: 12px;
    padding-left: 6px;
  }

}

.homepage_card {
  right: 0;
  bottom: 0;
  width: 33.33%;
  height: 50%;
}

.pad {
  padding: 12px 35px !important;
}

.green {
  width: 100%;
  height: 100%;
  background: #36635C;
}

.contacts_container {
  width: 100%;
  height: 100%;
  background: #C77347;

  svg {
    filter: invert(100%) sepia(8%) saturate(0%) hue-rotate(206deg) brightness(108%) contrast(140%)
  }

  h3,
  p {
    color: #FFFFFF
  }

  h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px
  }

  .icons_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 23px;
    .fb{
      width: 12px;
      height: 23px;
    }
    .tg{
      width: 18px;
      height: 17px;
    }
    .in{
      width: 15px;
      height: 15px;
    }
  }
.contacts_wrapper{
  padding: 16px 0 30px 17px;
  display: flex;
  flex-direction: column;
  gap:23px
}
  .contacts_info {
    display: flex;
    gap: 12px;
    flex-direction: column;
    .flex_2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}