.navbar_tablet {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 10vh;

  .grid_1 {
    width: 20vw;
  }

  .grid_2 {
    width: 29.8vw;
  }

  .grid_3 {
    width: 29.8vw;
  }

  .grid_4 {
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    width: 20vw;
  }

  .border {
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
  }

  .logo {
    width: 50px;
    height: 50px;
    padding: 20px 0 0 20px;
  }

  .menu {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    z-index: 3;
  }
}

.grid_tablet {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 79vh;

  .grid_1 {
    grid-area: 1 / 1 / 2 / 2;
    width: 20vw;
    position: relative;
  }

  .grid_2 {
    grid-area: 1 / 2 / 2 / 3;
    width: 29.8vw;
    position: relative;
  }

  .grid_3 {
    grid-area: 1 / 3 / 2 / 4;
    width: 29.8vw;
    position: relative;
  }

  .grid_4 {
    grid-area: 1 / 4 / 2 / 5;
    width: 20vw;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
  }

  .grid_5 {
    grid-area: 2 / 1 / 3 / 2;
    width: 20vw;
    position: relative;
  }

  .grid_6 {
    grid-area: 2 / 2 / 3 / 3;
    width: 29.8vw;
    position: relative;
  }

  .grid_7 {
    grid-area: 2 / 3 / 3 / 4;
    width: 29.8vw;
    position: relative;
  }

  .grid_8 {
    grid-area: 2 / 4 / 3 / 5;
    width: 20vw;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
  }

  .grid_9 {
    grid-area: 3 / 1 / 4 / 2;
    width: 20vw;
    position: relative;
  }

  .grid_10 {
    grid-area: 3 / 2 / 4 / 3;
    width: 29.8vw;
    position: relative;
  }

  .grid_11 {
    grid-area: 3 / 3 / 4 / 4;
    width: 29.8vw;
    position: relative;
  }

  .grid_12 {
    grid-area: 3 / 4 / 4 / 5;
    width: 20vw;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
  }

  .grid_13 {
    grid-area: 4 / 1 / 5 / 2;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    width: 20vw;
    position: relative;
  }

  .grid_14 {
    grid-area: 4 / 2 / 5 / 3;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    width: 29.8vw;
    position: relative;
  }

  .grid_15 {
    grid-area: 4 / 3 / 5 / 4;
    border-right: 1px solid rgba(40, 56, 53, 0.15);
    width: 29.8vw;
    position: relative;
  }

  .grid_16 {
    grid-area: 4 / 4 / 5 / 5;
    width: 20vw;
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    position: relative;
  }

  .border {
    border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);

  }
}

.footer_tablet {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 11vh;
  position: fixed;
  bottom: 0;

  .grid_1 {
    width: 20vw;
  }

  .grid_2 {
    width: 29.8vw;
  }

  .grid_3 {
    width: 29.8vw;
  }

  .grid_4 {
    width: 20vw;
    border-top: 1px solid rgba(40, 56, 53, 0.15);
  }

  .border {
    border-top: 1px solid rgba(40, 56, 53, 0.15);
    border-right: 1px solid rgba(40, 56, 53, 0.15);
  }
}

.icons_tablet {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  .fb {
    width: 12px;
    height: 23px;
  }

  .tg {
    width: 19px;
    height: 17px;
  }

  .in {
    width: 15px;
    height: 14px;
  }
}

.footer_info {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 5px 0 0 5px;
  position: fixed;

  .flex_2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;

    P {
      font-size: 14px;
    }
  }
}

.img_homepage_tab {
  width: 79.85%;
  position: absolute;
  right: 0;
  height: 49.4%;
  object-fit: cover;
  z-index: 1;
}

.line_logo {
  display: flex;
  height: 100%;
  align-items: center;
  gap: 33%;
  flex-direction: column;
  position: relative;

  .line_ {
    border-left: 2px solid #283835;
    height: 35%;
    position: absolute;
    left: 45px;
  }

  .logoName_ {
    transform: rotate(-90deg);
    font-weight: 400;
    font-size: 21px;
    line-height: 28px;
    color: #283835;
    position: absolute;
    bottom: 14%;
    left: 0;
  }
}

.menu_tablet {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;

  .flex_4 {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    position: absolute;
    right: 7%;
    top: 4%;
    padding: 10px 0 20px 0;
    width: 300px;
   

    a {
      font-weight: 400;
      font-size: 21px;
      color: #283835;
      text-decoration: none;
      padding:4px 4px 8px 37px;
      &:hover{
        background: #36635C;
        color: #ffffff
      }
    }

    .contact_us_menu {
      font-weight: 400;
      font-size: 21px;
      color: #283835;
      text-decoration: none;
      cursor: pointer;
      padding:4px 4px 8px 37px;
      &:hover{
        background: #36635C;
        color: #ffffff
      }
    }
  }
}

.arrow_tablet {
  position: absolute;
  bottom: 10%;
  right: 0;

  .team_arrow {
    display: flex;
    align-items: center;
    position: relative;

    .circle {
      position: absolute;
      cursor: pointer;
      left: 70px;
      width: 68px;
      height: 68px;

      &:hover {
        animation-duration: 0.8s;
        animation-name: fadeLeft;
        animation-fill-mode: both;

        @keyframes fadeLeft {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 1;
            transform: translateX(-15%)
          }
        }
      }
    }

    .arrow {
      width: 100px;
    }
  }
}