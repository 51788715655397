@media (max-width: 1660px) {
  .protonation input {
   font-size: 12px !important;
}
  .navbar {

    a,
    .link_about {
      font-size: 21px;
    }

    .logo {
      width: 50px;
      height: 50px;
    }
  }

  .description {
    left: 9.7%;

    h2 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }

  .logoName {
    font-size: 21px;
  }

  .page_card {
    h3 {
      font-size: 21px;
    }
  }

  .card_content {
    .card_numbers {
      .num_0 {
        font-size: 40px !important;
      }

      .num_page {
        font-size: 40px !important;
      }
    }

    svg {
      width: 12px;
      height: 24px;
    }
  }

  .page {
    .num_0 {
      font-size: 100px;
    }

    .num_page {
      font-size: 100px;
    }
  }

  .footer {
    svg {
      width: 21px;
      height: 38px;
    }
  }

  .progress_wrapper {
    .progress {
      width: 60px !important;

      .line {
        width: 26px !important;
      }
    }

    .fb {
      width: 15px;
      height: 29px;
    }

    .tg {
      width: 21px;
      height: 24px;
    }

    .in {
      width: 19px;
      height: 18px;
    }

    .grid_5 {
      .flex_3 {
        width: 50% !important;
      }
    }
  }

  .prediction {
    h3 {
      font-size: 21px;
    }

    svg {
      width: 12px;
      height: 24px;
    }
  }

  .protonation_desc {
    h2 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }

  .protonation {
    h3 {
      font-size: 21px;
    }

    button {
      font-size: 21px;
    }
  }

  .protonation_value .value {
    font-size: 21px;
  }

  .about_us_desc {
    font-size: 18px;
  }

  .read {
    font-size: 28px;
  }

  .slide_text {
    p {
      font-size: 28px;
    }

    .circle {
      right: -31px;
      width: 58px;
      height: 58px;
    }

    .arrow {
      width: 85px;
    }
  }

  .arrow_left {
    .circle {
      left: -31px;
      width: 58px;
      height: 58px;
    }

    .arrow {
      width: 85px;
    }
  }

  .about_us_container .about_us_content {

    h1,
    li {
      font-size: 20px;
    }
  }

  .team_info .team_info_container {
    h3 {
      font-size: 20px;
    }

    p {
      font-size: 17px;
    }
  }

  .contact_us .contact_us_content {
    h3 {
      font-size: 40px;
    }

    h4 {
      font-size: 20px;
    }

    .form {
      button {
        font-size: 21px;
      }
    }
  }

  .team_member_info {

    h3,
    p {
      font-size: 17px;
    }
  }
}

@media (max-width: 1230px) {
  .navbar {
    .link_about {
      font-size: 20px;
    }

    a {
      font-size: 20px;
    }
  }

  .footer {
    p {
      font-size: 13px;
    }
  }

  .protonation_desc p {
    font-size: 17px;
  }


  .about_us_desc {
    font-size: 17px;
  }

  .slide_text {
    .circle {
      right: -26px;
      width: 50px;
      height: 50px;
    }

    .arrow {
      width: 80px;
    }
  }

  .arrow_left {
    .circle {
      left: -26px;
      width: 50px;
      height: 50px;
    }

    .arrow {
      width: 80px;
    }
  }

  .description {
    left: 8.7%;
  }

  .arrow_wrapper .team_arrow {
    .circle {
      left: 60px;
      width: 58px;
      height: 58px;
    }

    .arrow {
      width: 85px;
    }
  }

  .circleleft {
    left: -28px;
    width: 58px;
    height: 58px;
  }
}

@media (max-width: 1440px) {
  .protonation input {
   font-size: 9px !important;
}
}

@media (max-width: 1140px) {
  .description {
    width: 80vw;
    left: 42%;
  }

  .line_wrapper {
    display: none;
  }

  .protonation {
    width: 50%;
    height: 30%;

    h3 {
      font-size: 18px;
    }

    input {
      height: 56px;
    }

    button {
      height: 58px;
    }
  }

  .protonation_value {
    height: 11.5%;
    bottom: 50.5%;
    width: 50%;

    .value {
      padding: 24px;
    }
  }

  .protonation_desc {
    top: 46%;
    width: 80vw;

    h2 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
    }
  }

  .slide_text {
    bottom: 0;

    .circle {
      right: -15px;
      width: 30px;
      height: 30px;
    }

    .arrow {
      width: 50px;
    }

    p {
      font-size: 20px;
    }
  }

  .arrow_left {
    bottom: 3%;
    right: 0;

    .circle {
      right: -15px;

      height: 30px;
    }

    .arrow {
      width: 50px;
    }
  }

  .read {
    font-size: 20px;
    bottom: 0;
  }

  .about_us_container {
    width: 50vw;
    height: 59.2vh;
    position: absolute;
    z-index: 1;
  }

  .about_us_desc {
    line-height: 22px;
    width: 80vw;
  }

  .flex_2 {
    .email {
      width: 20px;
      height: 14px;
    }

    .phone {
      width: 20px;
      height: 20px;
    }

    .location {
      width: 12px;
      height: 17px;
    }
  }

  .title_team {
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    color: #283835;
    position: absolute;
    right: 0;
    top: 40%;
  }

  .team_member_info {
    height: 39.5vh;
  }

  .arrow_wrapper {
    left: 0;
    bottom: 10%;

    .team_arrow {
      .arrow {
        width: 100px;
      }

      .circle {
        width: 68px;
        height: 68px;
      }
    }
  }

  .circleleft {
    width: 68px;
    height: 68px;
  }

  .contact_us {
    z-index: 4;

    .background {
      width: 100%;
    }

    .contact_us_content {
      position: absolute;
      width: 62%;
      left: 20%;
    }
  }

  #fade-out {
    animation: none;
  }

  #fade-outimg {
    animation: none;
  }

  #fade-outpage {
    animation: none;
  }

  #fade-outbg {
    animation: none;
  }

  #fade-outlogo {
    animation: none;
  }

  #fade-inpred {
    animation: none;
  }

  #fade-intext {
    animation: none;
  }

  #fade-inpage {
    animation: none;
  }

  #fade-inlogo {
    animation: none;
  }

  #fade-inpred1 {
    animation: none;
  }

  #fade-inpredopen {
    animation: none;
  }

  #fade-inpredValue {
    animation: none;
  }

  #fade-outpredpage {
    animation: none;
  }

  #fade-outpredpagenum {
    animation: none;
  }

  #fade-inpredlogo {
    animation: none;
  }

  #fade-inpreddesc {
    animation: none;
  }

  #fade-insolopen {
    animation: none;
  }

  #fade-inpredpage {
    animation: none;
  }

  #fade-outpred {
    animation: none;
  }

  #fade-inpageabout {
    animation: none;
  }

  #fade-inaboutdesc {
    animation: none;
  }

  #fade-inteam {
    animation: none;
  }
}

@media (max-width: 481px) {
  .page_card {
    gap: 20%;
  }

  .menu_tablet {
    .flex_4 {
      width: 192px !important;
      
      left: 60px;
      top: 47px !important;
      a{
        padding: 16px 0 19px 25px;
      }
      .contact_us_menu {
        padding: 16px 0 19px 25px;
      }
    }
  }

  .page_card {
    h3 {
      font-size: 15px;
    }

    .card_content {
      svg {
        width: 8px;
        height: 18px;
      }

      .card_numbers {
        .num_0 {
          font-size: 24px !important;
        }

        .num_page {
          font-size: 24px !important;
        }
      }
    }
  }

  .page {
    .num_0 {
      font-size: 60px;
    }

    .num_page {
      font-size: 60px;
    }
  }

  .progress_wrapper {
    left: 23%;
  }

  .description {
    left: 18vw;
    width: 75vw;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 13px;
    }
  }

  .prediction {
    h3 {
      font-size: 15px;
    }

    .flex {
      margin-bottom: 12px;

      svg {
        width: 8px;
        height: 18px;
      }
    }
  }

  .protonation {
    width: 100%;
    height: 189px;
    position: absolute;
    top: 0;

    h3 {
      font-size: 15px;
    }

    input {
      height: 44px;
    }

    button {
      height: 44px;
      font-size: 21px;
    }

    .flex_3 {
      padding: 12px 35px !important;
      gap: 2vh;
    }
  }

  .protonation_value {
    width: 100%;
    height: 125px;
    bottom: 0;

    .value {
      font-size: 15px;
    }
  }

  .protonation_desc {
    top: 0;
    width: 95vw;
    left: 0;
    padding: 2px 6px;
    display: block;

    p {
      font-size: 12px;
    }
  }

  .about_us_container {
    width: 66.67%;
    height: 100%;
    right: 0;

    .about_us_content {
      h1 {
        font-size: 13px;
        padding: 8px 0;
      }

      ul,
      li {
        font-size: 12px !important;
        padding-bottom: 1%;
      }
    }
  }

  .about_us_desc {
    line-height: 15px !important;
    width: 97vw;
    font-size: 13px;
  }

  .slide_text {
    bottom: 30%;

    p {
      font-size: 15px;

    }

    .arrow {
      width: 39px;
    }

    .circle {
      right: -10px;
      width: 20px;
      height: 21px;
    }
  }

  .read {
    font-size: 15px;
    bottom: 30%;
  }

  .arrow_left {
    bottom: 30%;
    left: 30%;

    .arrow {
      width: 39px;
    }

    .circle {
      left: -10px;
      width: 20px;
      height: 21px;
    }
  }

  .team_info .team_info_container {
    padding: 6%;
    h3 {
      font-size: 17px;
    }

    p {
      font-size: 13px;
    }
  }

  .team_member_info {
    display: flex;
    height: 200%;
    h3 {
      font-size: 12px;
      padding: 15% 5% 0 5%;
    }

    p {
      font-size: 12px;
      padding:  0 5% 20% 5%;
    }
  }

  .arrow_wrapper {
    left: 40%;
    bottom: 70%;

    .team_arrow {


      .arrow {
        width: 39px;
      }

      .circle {
        left: 28px;
        width: 20px;
        height: 21px;
      }
    }
  }

  .arrow_tablet {
    left: 30%;
    bottom: 70% !important;
    .team_arrow {
      .arrow {
        width: 39px !important;
      }

      .circleleft {
        left: -10px;
        width: 20px;
        height: 21px;
      }
    }
  }
  .contact_us{
    .contact_us_content{
      width: 100%;
      left: 0;
      h3{
        font-size: 24px;
      }
      h4{
        font-size: 24px;
      }
      input{
        height: 44px;
      }
      textarea{
        height: 25px;
      }
      button{
        height: 48px;
      }
    }
  }
}

@media (max-height: 820px) and (min-width: 1140px) {
  .about_us_container .about_us_content li {
    font-size: 15px;
  }

  .about_us_desc {
  
    font-size: 15px;
  }
  .description{
    p{
      font-size: 18px ;
    }
  }
  .protonation_desc {
    p {
      font-size: 17px !important ;
    }
  }
}


@media (max-width: 350px) {
  .protonation_desc {
    p {
      font-size: 12px !important;
    }
  }

  .protonation_title {
    p {
      font-size: 12px !important;
    }
  }
}
@media (min-width: 1470px) and (max-height: 990px) {
 .about_us_desc{
  font-size: 17px;
 }
 .protonation_desc {
  p {
    font-size: 18px ;
  }
}
.description{
  p{
    font-size: 20px ;
  }
}
}
