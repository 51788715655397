* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
}

body {
    font-family: 'Roboto';
}

.navbar {
    height: 10vh;

    .grid_1 {
        .logo {
            position: absolute;
            bottom: 0;
            left: 17%;
            cursor: pointer;
        }
    }

    .grid_6 {
        border-bottom: 1px solid rgba(40, 56, 53, 0.15);
    }

    .border {
        border-bottom: 1px solid rgba(40, 56, 53, 0.15);
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    a,
    .link_about {
        text-decoration: none;
        font-weight: 700;
        font-size: 23px;
        color: #283835;
        text-underline-offset: 10px;
        cursor: pointer;
    }

    .flex_2 {
        width: 100%;
        display: flex;
        position: absolute;
        bottom: 0;
        gap: 19%;
    }

}


.footer {
    height: 12vh;
    position: fixed;
    bottom: 0;
    width: 100vw;

    .grid_3 {
        .icons {
            height: 100%;
            align-items: center;
            display: flex;
            gap: 15%;
        }
    }

    .grid_4 {
        .flex_3 {
            display: flex;
            align-items: center;
            height: 100%;
            width: inherit;
            justify-content: center;
            gap: 10%;


            .phone {
                padding-left: 7%;
            }
        }
    }

    .grid_5 {
        .flex_3 {
            display: flex;
            align-items: center;
            height: 100%;
            position: absolute;
            width: 100%;
            gap: 1.7%;

            .location {
                padding-left: 1%;
            }
        }
    }

    .grid_6 {
        border-top: 1px solid rgba(40, 56, 53, 0.15);
    }

    .border {
        border-top: 1px solid rgba(40, 56, 53, 0.15);
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    p {
        font-weight: 400;
        font-size: 14px;
        color: #283835
    }
}

.progress_wrapper {
    gap: 15px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 35%;
    left: 10%;

    .progress {
        height: 2px;
        width: 80px;
        background: rgba(54, 99, 92, 0.49);
        position: relative;

        .line {
            height: 2px;
            width: 32px;
            background: #36635C;
            position: absolute;
        }
    }

}

.wrapper {
    grid-template-rows: repeat(3, 1fr);
    height: 78vh;

    .border {
        border-bottom: 1px solid rgba(40, 56, 53, 0.15);
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    .grid_13 {
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    .grid_14 {
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    .grid_15 {
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    .grid_16 {
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    .grid_17 {
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }

    .grid_18 {
        border-right: 1px solid rgba(40, 56, 53, 0.15);
    }
}

.grid_1 {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;

}

.grid_2 {
    grid-area: 1 / 2 / 2 / 3;
    position: relative;
}

.grid_3 {
    grid-area: 1 / 3 / 2 / 4;
    position: relative;
}

.grid_4 {
    grid-area: 1 / 4 / 2 / 5;
    position: relative;
}

.grid_5 {
    grid-area: 1 / 5 / 2 / 6;
}

.grid_6 {
    grid-area: 1 / 6 / 2 / 7;
    position: relative;
}

.grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

}

.grid_7 {
    grid-area: 2 / 1 / 3 / 2;
}

.grid_8 {
    grid-area: 2 / 2 / 3 / 3;
}

.grid_9 {
    grid-area: 2 / 3 / 3 / 4;
    position: relative;
}

.grid_10 {
    grid-area: 2 / 4 / 3 / 5;
    position: relative;

}

.grid_11 {
    grid-area: 2 / 5 / 3 / 6;
    position: relative;
}

.grid_12 {
    grid-area: 2 / 6 / 3 / 7;
    position: relative;
}

.grid_13 {
    grid-area: 3 / 1 / 4 / 2;
    position: relative;
}

.grid_14 {
    grid-area: 3 / 2 / 4 / 3;
    position: relative;
}

.grid_15 {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
}

.grid_16 {
    grid-area: 3 / 4 / 4 / 5;
    position: relative;
}

.grid_17 {
    grid-area: 3 / 5 / 4 / 6;
    position: relative;
}

.grid_18 {
    grid-area: 3 / 6 / 4 / 7;
    position: relative;
}

.grid_19 {
    grid-area: 1 / 5 / 4 / 7;
    position: relative;
}

.page {
    position: absolute;
    right: 0;
    bottom: 17px;
    display: flex;

    .num_0 {
        font-size: 120px;
        color: #F5F5F5;
    }

    .num_page {
        font-size: 120px;
        color: #D8D8D8;

    }
}

.description {
    gap: 14px;
    display: flex;
    position: absolute;
    flex-direction: column;
    left: 10.7%;
    width: 39vw;

    h2 {
        font-weight: 400;
        font-size: 50px;
        color: #283835;
        padding-top: 10px;
    }

    p {
        font-weight: 400;
        font-size: 24px;
        color: #283835;
    }
}

.line_wrapper {
    position: relative;
    width: 2px;
    height: 24vh;

    .line {
        position: absolute;
        width: 2px;
        border-left: 2px solid #283835;
        height: 80px;
        margin-left: 63px;
        bottom: 0;
    }
}

.logoName {
    transform: rotate(-90deg);
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    color: #283835;
    position: absolute;
    top: 35px;
    left: 13px;
}

.img_sm_container {
    position: relative;
    width: 100%;
    height: 100%;

    .img_small {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    .img_sm_bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        object-fit: cover;
    }
}

.page_card {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #C77347;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30%;
    transition: transform 1s;
    animation-fill-mode: forwards;
    z-index: 2;


    // @keyframes example {
    //     0%   { left:0px; top:0px;}
    //     100% { left:-100%; top:0px;}
    //   }
    h3 {
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        width: 53%;
    }

    .card_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 53%;
        text-decoration: none;
        cursor: pointer;

        .card_numbers {
            display: flex;
            align-items: center;
            justify-content: space-between;


            .num_0 {
                font-weight: 400;
                font-size: 50px;
                color: #DDB19A;
            }

            .num_page {
                font-weight: 400;
                font-size: 50px;
                color: #FFFFFF;
            }
        }
    }


}


.img_homepage {
    width: 50%;
    position: absolute;
    right: 0;
    height: 88%;
    object-fit: cover;
    z-index: 1;
}

.rectangle {
    height: 88%;
    position: absolute;
    right: 0;
    backdrop-filter: blur(5px);
    object-fit: cover;
    z-index: 1;
}

.prediction {
    display: flex;
    background: #36635C;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    position: absolute;


    h3 {
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        padding: 12% 15% 0 15%;
    }

    .flex {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
        cursor: pointer;

        svg {
            transform: rotate(90deg);
        }
    }

}

/* background: */
progress::-webkit-progress-bar {
    background-color: rgba(54, 99, 92, 0.2);
    width: 100%;
}

progress {
    background-color: rgba(54, 99, 92, 0.2)
}

/* value: */
progress::-webkit-progress-value {
    background-color: #36635C !important;
}

progress::-moz-progress-bar {
    background-color: #36635C !important;
}

progress {
    color: #36635C;
}

.protonation {
    position: fixed;
    background: #36635C;
    width: 33.4%;
    height: 78%;
    right: 0;
    top: 10%;
    z-index: 1;

    .flex_3 {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 48px 55px;
    }

    h3 {
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;

    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        border: 2px solid #FFFFFF;
        height: 60px;
        background: none;
        padding: 0 16px;
        color: white;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
          


        }

    }

    button {
        width: 100%;
        font-size: 24px;
        color: #FFFFFF;
        background: #C77347;
        border: none;
        height: 64px;
        cursor: pointer;
    }
}

.protonation_value {
    position: absolute;
    background: #C09D81;
    width: 33.4%;
    height: 26%;
    right: 0;
    bottom: 12%;
    z-index: 2;

    .value {
        font-weight: 400;
        font-size: 24px;
        color: #FFFFFF;
        padding: 48px 55px;
    }
}

.protonation_desc {
    position: absolute;
    display: flex;
    gap: 14px;
    flex-direction: column;
    top: 20%;
    left: 12%;
    width: 50vw;

    h2 {
        font-weight: 400;
        font-size: 50px;
        color: #283835;
    }

    p {
        font-weight: 400;
        font-size: 24px;
        color: #283835;
    }
}

.error {
    display: flex;
    gap: 10px;
    align-items: center;

    svg {
        width: 25px;
        height: 25px;
    }

    p {
        color: #CC0000;
        padding: 0;
    }
}

.about_us_desc {
    font-weight: 400;
    font-size: 24px;
    color: #283835;


}

.read {
    position: absolute;
    bottom: 6%;
    right: 0;
    font-size: 34px;
    color: #000000;
}

.slide_text {
    position: absolute;
    bottom: 6%;
    display: flex;
    align-items: center;
    gap: 13%;
    left: 3%;

    p {
        font-size: 34px;
        color: #000000;
    }

    .flex_2 {
        display: flex;
        align-items: center;
        position: relative;
    }

    .circle {
        position: absolute;
        right: -38px;
        cursor: pointer;
        width: 68px;
        height: 68px;

        &:hover {
            animation-duration: 0.8s;
            animation-name: fadeLeft;
            animation-fill-mode: both;

            @keyframes fadeLeft {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 1;
                    transform: translateX(-15%)
                }
            }
        }
    }

    .arrow {
        width: 100px;
    }
}

.about_us_container {
    width: 100%;
    height: 100%;

    .about_us_img {
        object-fit: cover;
        width: 100%;
        position: absolute;
        right: 0;
        height: 100%;
    }

    .about_us_content {
        position: absolute;
        padding: 0 9%;

        h1 {
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
            padding: 67px 0 32px 0;
        }

        ul,
        li {
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
            padding-bottom: 5%;
        }
    }
}

.arrow_left {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10%;
    right: -6%;

    .arrow {
        width: 100px;
    }

    .circle {
        position: absolute;
        left: -38px;
        cursor: pointer;
        width: 68px;
        height: 68px;

        &:hover {
            animation-duration: 0.8s;
            animation-name: fadeLeftArrow;
            animation-fill-mode: both;

            @keyframes fadeLeftArrow {
                0% {
                    opacity: 1;
                }

                100% {
                    opacity: 1;
                    transform: translateX(15%)
                }
            }
        }
    }
}

.arrow_wrapper {
    position: absolute;
    bottom: 20%;
    left: 30%;

    .team_arrow {
        display: flex;
        align-items: center;
        position: relative;

        .circle {
            position: absolute;
            cursor: pointer;
            left: 70px;
            width: 68px;
            height: 68px;

            &:hover {
                animation-duration: 0.8s;
                animation-name: fadeLeft;
                animation-fill-mode: both;

                @keyframes fadeLeft {
                    0% {
                        opacity: 1;
                    }

                    100% {
                        opacity: 1;
                        transform: translateX(-15%)
                    }
                }
            }
        }

        .arrow {
            width: 100px;
        }
    }
}

.team_img {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: 39% 12%; 
    }
}

.team_info {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;

    .team_info_container {
        display: flex;
        flex-direction: column;
        padding: 15%;
        gap: 15px;

        h3 {
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
        }

        p {
            font-weight: 400;
            font-size: 20px;
            color: rgba(255, 255, 255, 0.5);
        }
    }
}

.team_member_info {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    height: 52vh;
    width: 100%;
    left: 100%;
    bottom: 0;
    z-index: 1;
    display: none;


    h3 {
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        padding: 15% 15% 0 15%;
    }

    p {
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;
        padding: 0 15% 30% 15%;
    }
}

.contact_us {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: row;

    .background {
        width: 70%;
        height: 100%;
        background: rgba(87, 81, 74, 0.8);
    }

    .contact_us_content {
        width: 30%;
        height: 100%;
        background: #36635C;
        display: flex;
        flex-direction: column;

        h3 {
            font-weight: 400;
            font-size: 50px;
            color: #FFFFFF;
            margin-top: 55px;
            margin-bottom: 40px;
            padding: 0 8%;
        }

        h4 {
            font-weight: 400;
            font-size: 24px;
            color: #FFFFFF;
            margin-bottom: 16px;
            padding: 0 8%;
        }


        input {
            border: 2px solid #FFFFFF;
            height: 5.5vh;
            background: none;
            padding: 0 2.5%;
            color: white;
            width: 78%;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.5);

            }
        }

        textarea {
            border: 2px solid #FFFFFF;
            height: 7.5vh;
            background: none;
            padding: 2.5%;
            color: white;
            width: 78%;

            &:focus {
                outline: none;
            }

            &::placeholder {
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;

        button {
            height: 64px;
            width: 84%;
            background: #C77347;
            border: none;
            color: #FFFFFF;
            cursor: pointer;
            font-size: 21px;
        }
    }

}

.circleleft {
    position: absolute;
    left: -38px;
    cursor: pointer;
    width: 68px;
    height: 68px;

    &:hover {
        animation-duration: 0.8s;
        animation-name: fadeLeftArrow;
        animation-fill-mode: both;

        @keyframes fadeLeftArrow {
            0% {
                opacity: 1;
            }

            100% {
                opacity: 1;
                transform: translateX(15%)
            }
        }
    }
}